import React, { Component } from "react";
import gql from "graphql-tag";
import fetch from "node-fetch";
import CONTEXT from "../module/graphqlapi";
import client from "../module/Appoloconfig";


const QUERY_getuserdata = gql`
  query getuserdata(
    $start: Int
    $limit: Int
    $orderBy: String
    $order: String
    $fields: String
  ) {
    getuserdata(
      start: $start
      limit: $limit
      orderBy: $orderBy
      order: $order
      fields: $fields
    ) {
      id
    Admin_id
	  username
	  name
	  email
    }
  }
`;


const QUERY_getlistofhavan = gql`
  query getlistofhavan(
    $start: Int
    $limit: Int
    $orderBy: String
    $order: String
    $fields: String
  ) {
    getlistofhavan(
      start: $start
      limit: $limit
      orderBy: $orderBy
      order: $order
      fields: $fields
    ) {
      id
      receipId
      receipDate
      kundId
      kundType
      kundRow
      contributionAmount
      name
      phone
      address
      area
      city
      paymentMode
      paymentDate
      paymentDetails
      paymentDone
    }
  }
`;


const Adduserdata = gql`
  mutation createUser(
    $Admin_id: Int,
		$username: String,
		$password: String,
		$name: String,
		$email: String
  ) {
    createUser(
      Admin_id: $Admin_id,
      username: $username,
      password: $password,
      name: $name,
      email: $email
    ) {
      success
      message
    }
  }
`;

const Addhavandata = gql`
  mutation createHavan(
    $receipId: Int,
    $receipDate: String,
    $kundId: Int,
    $kundType: String,
    $kundRow: Int,
    $contributionAmount: Int,
    $name: String,
    $phone: String,
    $address: String,
    $area: String,
    $city: String,
    $paymentMode: String,
    $paymentDate: String,
    $paymentDetails: String,
    $paymentDone: String,
    $operation: String,
    $id: Int
  ) {
    createHavan(
      receipId:$receipId,
      receipDate:$receipDate,
      kundId:$kundId,
      kundType:$kundType,
      kundRow:$kundRow,
      contributionAmount:$contributionAmount,
      name:$name,
      phone:$phone,
      address:$address,
      area:$area,
      city:$city,
      paymentMode:$paymentMode,
      paymentDate:$paymentDate,
      paymentDetails:$paymentDetails,
      paymentDone:$paymentDone,
      operation: $operation,
      id:  $id
    ) {
      success
      message
    }
  }
`;

async function getUserList(getdata) {
    return new Promise((resolve, reject) => {
      client
        .query({
          query: QUERY_getuserdata,
          options: {
            fetch: fetch,
          },
          variables: {
            start: getdata.start,
            limit: getdata.limit,
            orderBy: getdata.orderBy,
            order: getdata.order,
            fields: JSON.stringify(getdata.fields),
          },
          context: CONTEXT,
        })
        .then((result) => {
          resolve(result);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }


  async function getHavanList(getdata) {
    return new Promise((resolve, reject) => {
      client
        .query({
          query: QUERY_getlistofhavan,
          options: {
            fetch: fetch,
          },
          variables: {
            start: getdata.start,
            limit: getdata.limit,
            orderBy: getdata.orderBy,
            order: getdata.order,
            fields: JSON.stringify(getdata.fields),
          },
          context: CONTEXT,
        })
        .then((result) => {
          resolve(result);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  async function AddUser(adddata) {
    return new Promise((resolve, reject) => {
      client
        .mutate({
          mutation: Adduserdata,
  
          variables: {
            Admin_id: adddata.admin_id,
            username: adddata.username,
            password: adddata.password,
            name: adddata.name,
            email: adddata.email
          },
          context: CONTEXT,
        })
        .then((result) => {
          resolve(result);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  async function Addhavan(adddata) {
    console.log("adddata",adddata)
    return new Promise((resolve, reject) => {
      client
        .mutate({
          mutation: Addhavandata,
  
          variables: {
            id: adddata.id,
            receipId:adddata.receipId,
            receipDate:adddata.receipDate,
            kundId:adddata.kundId,
            kundType:adddata.kundType,
            kundRow:adddata.kundRow,
            contributionAmount:adddata.contributionAmount,
            name:adddata.name,
            phone:adddata.phone,
            address:adddata.address,
            area:adddata.area,
            city:adddata.city,
            paymentMode:adddata.paymentMode,
            paymentDate:adddata.paymentDate,
            paymentDetails:adddata.paymentDetails,
            paymentDone:adddata.paymentDone,
            operation: adddata.operation
          },
          context: CONTEXT,
        })
        .then((result) => {
          resolve(result);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
  export {
    getUserList,
    getHavanList,
    AddUser,
    Addhavan
  };
  