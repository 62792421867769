import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import "../CSS/register.css";
import { AddUser } from "../graphql/graphql";

const Register = () => {
  const [registerData, setRegisterData] = useState({
    name: "",
    username: "",
    email: "",
    password: "",
    admin_id: 0,
  });

  const handleCheckBox = (e) => {
    e.target.checked === true
      ? (registerData.admin_id = "1")
      : (registerData.admin_id = "0");
  };

  const handleChangeRegister = (e) => {
    setRegisterData({ ...registerData, [e.target.name]: e.target.value });
  };

  const loginPage = useHistory();
  const handleRegister = () => {
    AddUser(registerData)
      .then((result) => {
        loginPage.push("/");
      })
      .catch((err) => {
        console.log("result", err);
      });
  };

  return (
    <>
      <div className="container-register">
        <h2 className="signup-title">Sign Up</h2>
        <form action className="register-form">
          <div className="form-item">
            <input
              type="text"
              className="register-input"
              name="name"
              id="name"
              placeholder="Name"
              onChange={(e) => handleChangeRegister(e)}
            />
          </div>
          <div className="form-item">
            <input
              type="text"
              className="register-input"
              name="username"
              id="username"
              placeholder="Username"
              onChange={(e) => handleChangeRegister(e)}
            />
          </div>
          <div className="form-item">
            <input
              type="email"
              className="register-input"
              name="email"
              id="email"
              placeholder="Email"
              onChange={(e) => handleChangeRegister(e)}
            />
          </div>
          <div className="form-item">
            <input
              type="password"
              className="register-input"
              name="password"
              id="password"
              placeholder="Enter password"
              onChange={(e) => handleChangeRegister(e)}
            />
          </div>
          <div class="w-100 form-check">
            <input
              class="form-check-input"
              type="checkbox"
              id="inlineCheckbox1"
              name="admin_id"
              onChange={(e) => handleCheckBox(e)}
            />
            <label class="form-check-label text-white" for="inlineCheckbox1">
              Are you Admin?
            </label>
          </div>

          <div className="form-btns">
            <button className="signup" type="button" onClick={handleRegister}>
              Register
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default Register;
