import React from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import PersonPinIcon from "@material-ui/icons/PersonPin";
import { NavLink } from "react-router-dom";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemText from "@material-ui/core/ListItemText";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  navbarHader: {
    borderRadius: 13,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    marginRight: 10,
    marginLeft: 10,
  },
  CartItemtitle: {
    display: "flex",
  },
  userButton: {
    display: "flex",
    justifyContent: "end",
    flex: "1",
  },
  logoutButton: {
    color: "black",
    textDecoration: "none",
  },
  userIcon: {
    height: 30,
    width: 30,
  },
}));

const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5",
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    "&:focus": {
      backgroundColor: theme.palette.primary.main,
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem);

const Navbar = ({ setUserData }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const showAdmin = localStorage.getItem("admin");

  const handleLogout = () => {
    localStorage.clear();
    setUserData(null);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <div className={classes.root}>
        <AppBar position="static" className={classes.navbarHader}>
          <Toolbar>
            <IconButton
              edge="start"
              className={classes.menuButton}
              color="inherit"
              aria-label="menu"
            >
              <MenuIcon />
            </IconButton>

            <Typography variant="h4" className={classes.title}>
              <NavLink exact to="/hawan" activeClassName="menu_link">
                List
              </NavLink>
            </Typography>

            {showAdmin === "1" && (
              <Typography variant="h4" className={classes.title}>
                <NavLink exact to="/form" activeClassName="menu_link">
                  Add User
                </NavLink>
              </Typography>
            )}

            <Typography variant="h4" className={classes.userButton}>
              <div>
                <PersonPinIcon
                  className={classes.userIcon}
                  onClick={handleClick}
                />
                <StyledMenu
                  id="customized-menu"
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                >
                  <StyledMenuItem>
                    <ListItemText primary={localStorage.getItem("name")} />
                  </StyledMenuItem>

                  <StyledMenuItem>
                    <NavLink exact to="/" className={classes.logoutButton}>
                      <ListItemText primary="Logout" onClick={handleLogout} />
                    </NavLink>
                  </StyledMenuItem>
                </StyledMenu>
              </div>
            </Typography>
          </Toolbar>
        </AppBar>
      </div>
    </>
  );
};

export default Navbar;
