import React, { useState } from "react";
import moment from "moment";
import { Addhavan } from "../graphql/graphql";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    margin: "10px",
    fontSize: "24px",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: "25ch",
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
    boxShadow: "unset",
  },
}));

const EditHawanData = ({ editData, handleEditClose, getApi }) => {
  const classes = useStyles();
  const [data, setData] = useState(editData);
  const [updateReceipDate, setUpdateReceipDate] = useState(data.receipDate);
  const [updatePaymentDate, setUpdatePaymentDate] = useState(data.paymentDate);
  const [isFormInvalid, setIsFormInvalid] = useState(false);

  const VALIDATION_MEAASGE = {
    receipId: "Enter the receip Id.",
    receipDate: "Enter the receip Date.",
    kundId: "Enter the kund Id.",
    kundType: "Enter the kund Type.",
    kundRow: "Enter the kund Row.",
    contributionAmount: "Enter the contribution Amount.",
    name: "Enter the name.",
    phone: "Enter the phone.",
    address: "Enter the address.",
    area: "Enter the area.",
    city: "Enter the city.",
    paymentMode: "Enter the payment Mode.",
    paymentDate: "Enter the payment Date.",
    paymentDetails: "Enter the payment Details.",
    paymentDone: "Enter the payment Done.",
  };

  const validate = (user) => {
    let errorObj = {};
    for (const item in user) {
      if (user[item] === "") {
        errorObj = { ...errorObj, [item]: VALIDATION_MEAASGE[item] };
      }
    }
    setIsFormInvalid(errorObj);
    return errorObj;
  };

  const isCheckErr = (fieldName) => {
    var isErr = false;
    for (const item in isFormInvalid) {
      if (isFormInvalid.hasOwnProperty(fieldName)) {
        isErr = true;
      }
    }
    return isErr;
  };

  const handleOnChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const handleOnChangeReceipDateUpdate = (date) => {
    var dateMomentObject = moment(date).format("YYYY-MM-DD");
    setUpdateReceipDate(dateMomentObject);
    data.receipDate = dateMomentObject;
  };

  const handleOnChangePaymentDateUpdate = (date) => {
    var dateMomentObject = moment(date).format("YYYY-MM-DD");
    setUpdatePaymentDate(dateMomentObject);
    data.paymentDate = dateMomentObject;
  };

  const handleUpdateData = () => {
    const dataUpdate = { ...data };
    const index = data.tableData.id;
    dataUpdate[index] = data;
    const checkErrObj = validate(data);
    data.operation = "update";
    if (Object.keys(checkErrObj).length === 0) {
      Addhavan(data)
        .then((result) => {
          handleEditClose();
          getApi();
        })
        .catch((err) => {
          console.log("update_data_error", err);
        });
    }
  };

  return (
    <Grid container spacing={3}>
      <Grid item md={6} xs={12}>
        <Paper className={classes.paper}>
          <TextField
            id="filled-full-width"
            label="Receip Id"
            name="receipId"
            value={data.receipId}
            placeholder="Enter Receip Id."
            fullWidth
            margin="normal"
            InputLabelProps={{
              shrink: true,
            }}
            variant="filled"
            onChange={(e) => handleOnChange(e)}
            error={isCheckErr("receipId")}
            helperText={isFormInvalid?.receipId}
          />
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              margin="normal"
              id="date-picker-dialog"
              label="Receip Date"
              name="receipDate"
              format="yyyy/MM/dd"
              value={updateReceipDate}
              onChange={handleOnChangeReceipDateUpdate}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
              fullWidth
            />
          </MuiPickersUtilsProvider>
          <TextField
            id="filled-full-width"
            label="Kund Id"
            name="kundId"
            value={data.kundId}
            placeholder="Enter Kund Id."
            fullWidth
            margin="normal"
            InputLabelProps={{
              shrink: true,
            }}
            variant="filled"
            onChange={(e) => handleOnChange(e)}
            error={isCheckErr("kundId")}
            helperText={isFormInvalid?.kundId}
          />
          <TextField
            id="filled-full-width"
            label="Kund Type"
            name="kundType"
            value={data.kundType}
            placeholder="Enter Kund Type."
            fullWidth
            margin="normal"
            InputLabelProps={{
              shrink: true,
            }}
            variant="filled"
            onChange={(e) => handleOnChange(e)}
            error={isCheckErr("kundType")}
            helperText={isFormInvalid?.kundType}
          />
          <TextField
            id="filled-full-width"
            label="Kund Row"
            name="kundRow"
            value={data.kundRow}
            placeholder="Enter Kund Row."
            fullWidth
            margin="normal"
            InputLabelProps={{
              shrink: true,
            }}
            variant="filled"
            onChange={(e) => handleOnChange(e)}
            error={isCheckErr("kundRow")}
            helperText={isFormInvalid?.kundRow}
          />
          <TextField
            id="filled-full-width"
            label="Contribution Amount"
            name="contributionAmount"
            value={data.contributionAmount}
            placeholder="Enter Contribution Amount."
            fullWidth
            margin="normal"
            InputLabelProps={{
              shrink: true,
            }}
            variant="filled"
            onChange={(e) => handleOnChange(e)}
            error={isCheckErr("contributionAmount")}
            helperText={isFormInvalid?.contributionAmount}
          />
          <TextField
            id="filled-full-width"
            label="Name"
            name="name"
            value={data.name}
            placeholder="Enter Name."
            fullWidth
            margin="normal"
            InputLabelProps={{
              shrink: true,
            }}
            variant="filled"
            onChange={(e) => handleOnChange(e)}
            error={isCheckErr("name")}
            helperText={isFormInvalid?.name}
          />
          <TextField
            id="filled-full-width"
            label="Phone"
            name="phone"
            value={data.phone}
            placeholder="Enter Phone Number."
            fullWidth
            margin="normal"
            InputLabelProps={{
              shrink: true,
            }}
            variant="filled"
            onChange={(e) => handleOnChange(e)}
            error={isCheckErr("phone")}
            helperText={isFormInvalid?.phone}
          />
        </Paper>
      </Grid>
      <Grid item md={6} xs={12}>
        <Paper className={classes.paper}>
          <TextField
            id="filled-full-width"
            label="Address"
            name="address"
            value={data.address}
            placeholder="Enter Address."
            fullWidth
            margin="normal"
            InputLabelProps={{
              shrink: true,
            }}
            variant="filled"
            onChange={(e) => handleOnChange(e)}
            error={isCheckErr("address")}
            helperText={isFormInvalid?.address}
          />
          <TextField
            id="filled-full-width"
            label="Area"
            name="area"
            value={data.area}
            placeholder="Enter Area."
            fullWidth
            margin="normal"
            InputLabelProps={{
              shrink: true,
            }}
            variant="filled"
            onChange={(e) => handleOnChange(e)}
            error={isCheckErr("area")}
            helperText={isFormInvalid?.area}
          />
          <TextField
            id="filled-full-width"
            label="City"
            name="city"
            value={data.city}
            placeholder="Enter City."
            fullWidth
            margin="normal"
            InputLabelProps={{
              shrink: true,
            }}
            variant="filled"
            onChange={(e) => handleOnChange(e)}
            error={isCheckErr("city")}
            helperText={isFormInvalid?.city}
          />
          <TextField
            id="filled-full-width"
            label="Payment Mode"
            name="paymentMode"
            value={data.paymentMode}
            placeholder="Enter Payment Mode."
            fullWidth
            margin="normal"
            InputLabelProps={{
              shrink: true,
            }}
            variant="filled"
            onChange={(e) => handleOnChange(e)}
            error={isCheckErr("paymentMode")}
            helperText={isFormInvalid?.paymentMode}
          />
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              margin="normal"
              id="date-picker-dialog"
              label="Payment Date"
              name="paymentDate"
              format="yyyy/MM/dd"
              value={updatePaymentDate}
              onChange={handleOnChangePaymentDateUpdate}
              KeyboardButtonProps={{
                "aria-label": "change date",
              }}
              fullWidth
            />
          </MuiPickersUtilsProvider>
          <TextField
            id="filled-full-width"
            label="Payment Details"
            name="paymentDetails"
            value={data.paymentDetails}
            placeholder="Enter Payment Details."
            fullWidth
            margin="normal"
            InputLabelProps={{
              shrink: true,
            }}
            variant="filled"
            onChange={(e) => handleOnChange(e)}
            error={isCheckErr("paymentDetails")}
            helperText={isFormInvalid?.paymentDetails}
          />
          <TextField
            id="filled-full-width"
            label="Payment Done"
            name="paymentDone"
            value={data.paymentDone}
            placeholder="Enter Payment Done."
            fullWidth
            margin="normal"
            InputLabelProps={{
              shrink: true,
            }}
            variant="filled"
            onChange={(e) => handleOnChange(e)}
            error={isCheckErr("paymentDone")}
            helperText={isFormInvalid?.paymentDone}
          />
          <div className="d-flex justify-content-end">
            <Button
              variant="contained"
              className="m-4"
              onClick={handleEditClose}
            >
              cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              className="m-4"
              onClick={handleUpdateData}
            >
              Save
            </Button>
          </div>
        </Paper>
      </Grid>
    </Grid>
    // </form>
  );
};

export default EditHawanData;
