import React, { useState, useEffect } from "react";
import moment from "moment";
import Navbar from "../Navbar";
import { useHistory } from "react-router-dom";
import { Addhavan } from "../graphql/graphql";

import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import "../CSS/datafrom.css";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    margin: "10px",
    fontSize: "24px",
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: "25ch",
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
    boxShadow: "unset",
  },
}));

const DataFrom = ({ userData }) => {
  const classes = useStyles();
  const history = useHistory();
  const nweDate = new Date();
  const dateFormate = moment(nweDate).format("YYYY-MM-DD");

  const [user, setUser] = useState({
    receipId: "",
    receipDate: "",
    kundId: "",
    kundType: "",
    kundRow: "",
    contributionAmount: "",
    name: "",
    phone: "",
    address: "",
    area: "",
    city: "",
    paymentMode: "",
    paymentDate: "",
    paymentDetails: "",
    paymentDone: "",
  });
  const [receipDate, setReceipDate] = useState(dateFormate);
  const [paymentDate, setPaymentDate] = useState(dateFormate);
  const [isFormInvalid, setIsFormInvalid] = useState(false);

  useEffect(() => {
    userData === null && history.push("/");
  }, [userData]);

  const handleOnChange = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
    e.target.value !== "" && delete isFormInvalid[e.target.name];
    validate({ [e.target.name]: e.target.value });
  };

  const VALIDATION_MEAASGE = {
    receipId: "Enter the receip Id.",
    receipDate: "Enter the receip Date.",
    kundId: "Enter the kund Id.",
    kundType: "Enter the kund Type.",
    kundRow: "Enter the kund Row.",
    contributionAmount: "Enter the contribution Amount.",
    name: "Enter the name.",
    phone: "Enter the phone.",
    address: "Enter the address.",
    area: "Enter the area.",
    city: "Enter the city.",
    paymentMode: "Enter the payment Mode.",
    paymentDate: "Enter the payment Date.",
    paymentDetails: "Enter the payment Details.",
    paymentDone: "Enter the payment Done.",
  };

  const validate = (user) => {
    let errorObj = {};
    for (const item in user) {
      if (user[item] === "") {
        errorObj = { ...errorObj, [item]: VALIDATION_MEAASGE[item] };
      }
    }
    setIsFormInvalid(errorObj);
    return errorObj;
  };

  const handleFormSubmit = async () => {
    user.receipDate = receipDate;
    user.paymentDate = paymentDate;
    const checkErrObj = validate(user);
    user.operation = "insert";
    if (Object.keys(checkErrObj).length === 0) {
      Addhavan(user)
        .then((result) => {
          handleFormClear();
        })
        .catch((err) => {
          console.log("add_data_error", err);
        });
    }
  };

  const handleFormClear = () => {
    setUser({
      receipId: "",
      receipDate: "",
      kundId: "",
      kundType: "",
      kundRow: "",
      contributionAmount: "",
      name: "",
      phone: "",
      address: "",
      area: "",
      city: "",
      paymentMode: "",
      paymentDate: "",
      paymentDetails: "",
      paymentDone: "",
    });
  };

  const handleReceipDateChange = (date) => {
    var dateMomentObject = moment(date).format("YYYY-MM-DD");
    setReceipDate(dateMomentObject);
  };

  const handlePaymentDateChange = (date) => {
    var dateMomentObject = moment(date).format("YYYY-MM-DD");
    setPaymentDate(dateMomentObject);
  };

  const isCheckErr = (fieldName) => {
    var isErr = false;
    for (const item in isFormInvalid) {
      if (isFormInvalid.hasOwnProperty(fieldName)) {
        isErr = true;
      }
    }
    return isErr;
  };

  return (
    <>
      <Navbar />
      <form className={classes.root} noValidate autoComplete="off">
        <Grid container spacing={3}>
          <Grid item md={6} xs={12}>
            <Paper className={classes.paper}>
              <TextField
                id="filled-full-width"
                label="Receip Id"
                name="receipId"
                value={user?.receipId}
                placeholder="Enter Receip Id."
                fullWidth
                margin="normal"
                InputLabelProps={{
                  shrink: true,
                }}
                variant="filled"
                onChange={(e) => handleOnChange(e)}
                error={isCheckErr("receipId")}
                helperText={isFormInvalid?.receipId}
              />
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  margin="normal"
                  id="date-picker-dialog"
                  label="Receip Date"
                  name="receipDate"
                  format="yyyy/MM/dd"
                  value={receipDate}
                  onChange={handleReceipDateChange}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                  fullWidth
                  minDate={moment().toDate()}
                />
              </MuiPickersUtilsProvider>
              <TextField
                id="filled-full-width"
                label="Kund Id"
                name="kundId"
                value={user?.kundId}
                placeholder="Enter Kund Id."
                fullWidth
                margin="normal"
                InputLabelProps={{
                  shrink: true,
                }}
                variant="filled"
                onChange={(e) => handleOnChange(e)}
                error={isCheckErr("kundId")}
                helperText={isFormInvalid?.kundId}
              />
              <TextField
                id="filled-full-width"
                label="Kund Type"
                name="kundType"
                value={user?.kundType}
                placeholder="Enter Kund Type."
                fullWidth
                margin="normal"
                InputLabelProps={{
                  shrink: true,
                }}
                variant="filled"
                onChange={(e) => handleOnChange(e)}
                error={isCheckErr("kundType")}
                helperText={isFormInvalid?.kundType}
              />
              <TextField
                id="filled-full-width"
                label="Kund Row"
                name="kundRow"
                value={user?.kundRow}
                placeholder="Enter Kund Row."
                fullWidth
                margin="normal"
                InputLabelProps={{
                  shrink: true,
                }}
                variant="filled"
                onChange={(e) => handleOnChange(e)}
                error={isCheckErr("kundRow")}
                helperText={isFormInvalid?.kundRow}
              />
              <TextField
                id="filled-full-width"
                label="Contribution Amount"
                name="contributionAmount"
                value={user?.contributionAmount}
                placeholder="Enter Contribution Amount."
                fullWidth
                margin="normal"
                InputLabelProps={{
                  shrink: true,
                }}
                variant="filled"
                onChange={(e) => handleOnChange(e)}
                error={isCheckErr("contributionAmount")}
                helperText={isFormInvalid?.contributionAmount}
              />
              <TextField
                id="filled-full-width"
                label="Name"
                name="name"
                value={user?.name}
                placeholder="Enter Name."
                fullWidth
                margin="normal"
                InputLabelProps={{
                  shrink: true,
                }}
                variant="filled"
                onChange={(e) => handleOnChange(e)}
                error={isCheckErr("name")}
                helperText={isFormInvalid?.name}
              />
              <TextField
                id="filled-full-width"
                label="Phone"
                name="phone"
                value={user?.phone}
                placeholder="Enter Phone Number."
                fullWidth
                margin="normal"
                InputLabelProps={{
                  shrink: true,
                }}
                variant="filled"
                onChange={(e) => handleOnChange(e)}
                error={isCheckErr("phone")}
                helperText={isFormInvalid?.phone}
              />
            </Paper>
          </Grid>
          <Grid item md={6} xs={12}>
            <Paper className={classes.paper}>
              <TextField
                id="filled-full-width"
                label="Address"
                placeholder="Enter Address."
                name="address"
                value={user?.address}
                onChange={(e) => handleOnChange(e)}
                fullWidth
                margin="normal"
                InputLabelProps={{
                  shrink: true,
                }}
                variant="filled"
                error={isCheckErr("address")}
                helperText={isFormInvalid?.address}
              />
              <TextField
                id="filled-full-width"
                label="Area"
                placeholder="Enter Area."
                name="area"
                value={user?.area}
                onChange={(e) => handleOnChange(e)}
                fullWidth
                margin="normal"
                InputLabelProps={{
                  shrink: true,
                }}
                variant="filled"
                error={isCheckErr("area")}
                helperText={isFormInvalid?.area}
              />
              <TextField
                id="filled-full-width"
                label="City"
                placeholder="Enter City."
                name="city"
                value={user?.city}
                onChange={(e) => handleOnChange(e)}
                fullWidth
                margin="normal"
                InputLabelProps={{
                  shrink: true,
                }}
                variant="filled"
                error={isCheckErr("city")}
                helperText={isFormInvalid?.city}
              />
              <TextField
                id="filled-full-width"
                label="Payment Mode"
                placeholder="Enter Payment Mode."
                name="paymentMode"
                value={user?.paymentMode}
                onChange={(e) => handleOnChange(e)}
                fullWidth
                margin="normal"
                InputLabelProps={{
                  shrink: true,
                }}
                variant="filled"
                error={isCheckErr("paymentMode")}
                helperText={isFormInvalid?.paymentMode}
              />
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  margin="normal"
                  id="date-picker-dialog"
                  label="Payment Date"
                  name="paymentDate"
                  format="yyyy/MM/dd"
                  value={paymentDate}
                  onChange={handlePaymentDateChange}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                  fullWidth
                  minDate={moment().toDate()}
                />
              </MuiPickersUtilsProvider>
              <TextField
                id="filled-full-width"
                label="Payment Details"
                placeholder="Enter Payment Details."
                name="paymentDetails"
                value={user?.paymentDetails}
                onChange={(e) => handleOnChange(e)}
                fullWidth
                margin="normal"
                InputLabelProps={{
                  shrink: true,
                }}
                variant="filled"
                error={isCheckErr("paymentDetails")}
                helperText={isFormInvalid?.paymentDetails}
              />
              <TextField
                id="filled-full-width"
                label="Payment Done"
                placeholder="Enter Payment Done."
                name="paymentDone"
                value={user?.paymentDone}
                onChange={(e) => handleOnChange(e)}
                fullWidth
                margin="normal"
                InputLabelProps={{
                  shrink: true,
                }}
                variant="filled"
                error={isCheckErr("paymentDone")}
                helperText={isFormInvalid?.paymentDone}
              />
              <div className="d-flex justify-content-end">
                <Button
                  variant="contained"
                  className="m-4"
                  onClick={handleFormClear}
                >
                  Clear
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  className="m-4"
                  onClick={handleFormSubmit}
                >
                  Save
                </Button>
              </div>
            </Paper>
          </Grid>
        </Grid>
      </form>
    </>
  );
};

export default DataFrom;
