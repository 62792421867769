import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import "../CSS/login.css";
import { getUserList } from "../graphql/graphql";

const Login = ({ setUserData, userData }) => {
  const history = useHistory();
  const [userName, setUserName] = useState();
  const [password, setPassword] = useState();

  useEffect(() => {
    userData !== null && history.push("/hawan");
  }, [userData]);

  const productPage = useHistory();
  const handleSubmit = () => {
    const getdata = {
      start: 0,
      limit: 10,
      orderBy: "email",
      order: "ASC",
      fields: [
        { name: "username", value: userName, operator: "=" },
        { name: "password", value: password, operator: "=" },
      ],
    };

    getUserList(getdata)
      .then((result) => {
        if (result && result.data.getuserdata.length > 0) {
          let data = result.data.getuserdata;
          localStorage.setItem("name", data[0].name);
          localStorage.setItem("userId", data[0].id);
          localStorage.setItem("admin", data[0].Admin_id);
          setUserData(data[0].id);
          productPage.push("/hawan");
        } else {
          productPage.push("/register");
        }
      })
      .catch((err) => {
        console.log("result", err);
      });
  };

  const RegisterPage = useHistory();
  const handleRegister = () => {
    RegisterPage.push("/register");
    setUserData(null);
  };

  return (
    <>
      <div class="background"></div>
      <div class="login-container">
        <h2 class="login-title">Login</h2>
        <form action="" className="login-form">
          <div class="form-item">
            <input
              type="text"
              id="text"
              placeholder="username"
              className="login-input"
              name="username"
              onChange={(e) => setUserName(e.target.value)}
            />
          </div>

          <div class="form-item">
            <input
              type="password"
              id="pass"
              className="login-input"
              placeholder="password"
              name="password"
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>

          <div>
            <button type="button" className="login-btn" onClick={handleSubmit}>
              LOGIN
            </button>
            <button
              type="button"
              className="login-btn"
              onClick={handleRegister}
            >
              Register
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

export default Login;
