import React, { useState, useEffect, forwardRef } from "react";
import { getHavanList, Addhavan } from "../graphql/graphql";
import { useHistory } from "react-router-dom";
import Navbar from "../Navbar";
import EditHawanData from "../component/EditHawanData";
import MaterialTable from "material-table";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import Modal from "@material-ui/core/Modal";
import { makeStyles } from "@material-ui/core/styles";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import "../CSS/hawan.css";

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    width: "80%",
  },
}));

const Hawan = ({ setUserData, userData }) => {
  const classes = useStyles();
  const history = useHistory();
  const [data, setData] = useState([]);
  const [editModal, setEditModal] = useState(false);
  const [editData, setEditData] = useState();

  const showAdmin = localStorage.getItem("admin");

  const column = [
    {
      title: "Receip Id",
      field: "receipId",
      cellStyle: { minWidth: 100 },
    },
    {
      title: "Receip Date",
      field: "receipDate",
      cellStyle: { minWidth: 130 },
    },
    {
      title: "Kund Id",
      field: "kundId",
      cellStyle: { minWidth: 100 },
    },
    {
      title: "Kund Type",
      field: "kundType",
      cellStyle: { minWidth: 120 },
    },
    {
      title: "Kund Row",
      field: "kundRow",
      cellStyle: { minWidth: 120 },
    },
    {
      title: "Contribution Amount",
      field: "contributionAmount",
      cellStyle: { minWidth: 150 },
    },
    {
      title: "Name",
      field: "name",
      cellStyle: { minWidth: 120 },
    },
    {
      title: "Phone",
      field: "phone",
      cellStyle: { minWidth: 120 },
    },
    {
      title: "Address",
      field: "address",
      cellStyle: { minWidth: 200 },
    },
    {
      title: "Area",
      field: "area",
      cellStyle: { minWidth: 120 },
    },
    {
      title: "City",
      field: "city",
      cellStyle: { minWidth: 120 },
    },
    {
      title: "Payment Mode",
      field: "paymentMode",
      cellStyle: { minWidth: 120 },
    },
    {
      title: "Payment Date",
      field: "paymentDate",
      cellStyle: { minWidth: 120 },
    },
    {
      title: "Payment Details",
      field: "paymentDetails",
      cellStyle: { minWidth: 120 },
    },
    {
      title: "Payment Done",
      field: "paymentDone",
      cellStyle: { minWidth: 120 },
    },
  ];

  const getApi = async () => {
    const getdata = {
      start: 0,
      limit: 10,
      orderBy: "email",
      order: "ASC",
      fields: [{ name: "id", value: "", operator: "!=" }],
    };

    getHavanList(getdata)
      .then((result) => {
        if (result && result.data.getlistofhavan) {
          let data = result.data.getlistofhavan;
          setData(data.reverse());
        }
      })
      .catch((err) => {
        console.log("get_data_error", err);
      });
  };

  const handleEditOpen = (event, rowData) => {
    setEditModal(true);
    setEditData(rowData);
  };
  const handleEditClose = () => {
    setEditModal(false);
  };

  useEffect(() => {
    getApi();
    userData === null && history.push("/");
  }, [userData]);

  return (
    <>
      <Navbar setUserData={setUserData} />
      <MaterialTable
        icons={tableIcons}
        title="Hawan Deatils"
        options={{
          filtering: true,
          headerStyle: {
            backgroundColor: "#EEE",
          },
        }}
        columns={column}
        data={data}
        actions={[
          (rowData) => ({
            icon: EditOutlinedIcon,
            tooltip: "Edit",
            onClick: (event, rowData) => handleEditOpen(event, rowData),
          }),
        ]}
        editable={{
          onRowDelete:
            showAdmin === "1"
              ? (oldData) =>
                  new Promise((resolve, reject) => {
                    const dataDelete = [...data];
                    const index = oldData.tableData.id;
                    let id = dataDelete[index].id;
                    oldData.operation = "delete";
                    Addhavan(oldData)
                      .then((result) => {
                        getApi();
                      })
                      .catch((err) => {
                        console.log("delete_data_error", err);
                      });
                    resolve();
                  })
              : null,
        }}
      />

      <Modal
        className={classes.modal}
        open={editModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={editModal}>
          <div className={classes.paper}>
            <EditHawanData
              editData={editData}
              handleEditClose={handleEditClose}
              getApi={getApi}
            />
          </div>
        </Fade>
      </Modal>
    </>
  );
};

export default Hawan;
