import fetch from "node-fetch";
import { InMemoryCache } from "apollo-cache-inmemory";
import { ApolloClient } from "apollo-client";
import { createHttpLink, HttpLink } from "apollo-link-http";
const defaultOptions = {
  watchQuery: {
    fetchPolicy: "no-cache",
    errorPolicy: "ignore",
  },
  query: {
    fetchPolicy: "no-cache",
    errorPolicy: "all",
  },
};

const client = new ApolloClient({
  link: createHttpLink({
    uri: "https://ok7objbagnhpppuj33m44i5vjm.appsync-api.ap-northeast-1.amazonaws.com/graphql",
    fetch: fetch,
  }),
  cache: new InMemoryCache({
    dataIdFromObject: (object) => object.key || null,
  }),
  defaultOptions,
});

export default client;
