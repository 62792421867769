import React, { Component } from "react";

const CONTEXT = {
  credentials: "omit",
  headers: {
    region: "us-west-2",
    authenticationType: "API_KEY",
    "x-api-key": "da2-ni5zhkvchreexhledgq36o6rju",
  },
};

export default CONTEXT;
