import React, { useEffect } from "react";
import {
  BrowserRouter,
  Switch,
  Route,
  useHistory,
  useLocation,
} from "react-router-dom";
import Login from "./pages/Login";
import Register from "./pages/Register";
import Hawan from "./pages/Hawan";
import UserData from "./pages/DataFrom";
import { useState } from "react";

const App = () => {
  const isUser = localStorage.getItem("userId");
  const history = useHistory();
  let location = useLocation();
  const [userData, setUserData] = useState(isUser);

  useEffect(() => {
    userData === null
      ? history.push(location?.pathname === "/register" ? "register" : "/")
      : history.push("/hawan");
  }, [userData]);

  return (
    <>
      <BrowserRouter>
        <Switch>
          <Route
            exact
            path="/"
            render={(props) => (
              <Login userData={userData} setUserData={setUserData} />
            )}
          />
          <Route
            path="/register"
            render={(props) => <Register userData={userData} />}
          />
          <Route
            path="/hawan"
            render={(props) => (
              <Hawan userData={userData} setUserData={setUserData} />
            )}
          />
          <Route
            path="/form"
            render={(props) => (
              <UserData userData={userData} setUserData={setUserData} />
            )}
          />
        </Switch>
      </BrowserRouter>
    </>
  );
};

export default App;
